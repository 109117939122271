import React, { HTMLAttributes, ReactElement, useMemo } from 'react'
import { RadioGroupContext, RadioGroupContextValue } from './useRadio'

export type TRadioGroupHTMLProps = Omit<
  HTMLAttributes<HTMLDivElement>,
  'onChange'
>

type TRadioGroup = RadioGroupContextValue & TRadioGroupHTMLProps

export const RadioGroup = ({
  children,
  name,
  value,
  onChange,
  onBlur,
  ...htmlProps
}: TRadioGroup): ReactElement => {
  const contextValue = useMemo(
    () => ({
      name,
      value,
      onChange,
      onBlur,
    }),
    [name, value, onChange, onBlur]
  )
  return (
    <RadioGroupContext.Provider value={contextValue}>
      <div role="group" {...htmlProps}>
        {children}
      </div>
    </RadioGroupContext.Provider>
  )
}
