import React, { ReactElement, ReactNode } from 'react'
import { useFormControlInput } from '@mindfulchefuk/forms/hooks'
import { RadioBox } from '@mindfulchefuk/design-system'
import { useFormikContext } from 'formik'

type TFormRadioBox = {
  children: ReactNode
  'data-testid'?: string
  value: string | number
}

export const FormRadioBox = ({
  children,
  'data-testid': testId,
  value,
}: TFormRadioBox): ReactElement => {
  const { setFieldValue } = useFormikContext()
  const field = useFormControlInput<string>()
  const checked = field.value === value

  const toggleChecked = () => {
    if (checked) {
      setFieldValue(field.name, '')
    }
  }

  return (
    <RadioBox
      {...field}
      value={value}
      data-testid={testId}
      checked={checked}
      onClick={toggleChecked}
    >
      {children}
    </RadioBox>
  )
}
