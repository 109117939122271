import React, { ReactElement } from 'react'
import { useFormControlInput } from '@mindfulchefuk/forms/hooks'
import {
  Select,
  ISelect,
} from '@mindfulchefuk/design-system/Forms/Select/Select'

export const FormSelect = (props: ISelect): ReactElement => {
  const fieldProps = useFormControlInput<string | number>()

  return <Select {...fieldProps} {...props} />
}
