import React, { ReactElement } from 'react'
import { useFormControlInput } from '@mindfulchefuk/forms/hooks'
import {
  Textarea,
  TTextArea,
} from '@mindfulchefuk/design-system/Forms/Textarea'
import { Flex, Text } from '@mindfulchefuk/design-system'
import plural from '@mindfulchefuk/utils/plural'

export const FormTextarea = (htmlProps: TTextArea): ReactElement => {
  const fieldProps = useFormControlInput<string>()
  const characterCount = fieldProps.value.toString().length
  const { maxLength } = htmlProps
  const charactersRemaining = maxLength - characterCount
  const showCharacterCount = !!maxLength

  return (
    <>
      <Textarea {...fieldProps} {...htmlProps} />
      {showCharacterCount && (
        <Flex justifyContent="flex-end">
          <Text variant="body-sm">
            {charactersRemaining ?? 0}{' '}
            {plural('character', 'characters')(charactersRemaining)} remaining
          </Text>
        </Flex>
      )}
    </>
  )
}
