import { string } from 'yup'

export const validateSafePlace = string().required('Safe place is required')

export const validateSafePlaceOther = string().when('safePlace', {
  is: 'Other',
  then: (safePlace) =>
    safePlace
      .required('Safe place is required')
      .max(50, 'Safe place must be 50 characters or less')
      .trim(),
})
