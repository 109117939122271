import React, { ReactElement } from 'react'
import { Label, ILabel } from '@mindfulchefuk/design-system/Forms/Label'
import { useFormControlLabel } from '@mindfulchefuk/forms/hooks'

export const FormLabel = ({
  as = 'label',
  children,
  ...htmlProps
}: ILabel): ReactElement => {
  const [htmlFor, dataProps] = useFormControlLabel(as)

  return (
    <Label as={as} htmlFor={htmlFor} {...dataProps} {...htmlProps}>
      {children}
    </Label>
  )
}
