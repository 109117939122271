import React, { ReactElement, ReactNode } from 'react'
import { useFormControlInput } from '@mindfulchefuk/forms/hooks'
import { RadioButton } from '@mindfulchefuk/design-system'

type TFormRadioButton = {
  children: ReactNode
  'data-testid'?: string
  value: string | number
}

export const FormRadioButton = ({
  value,
  'data-testid': testId,
  children,
}: TFormRadioButton): ReactElement => {
  const field = useFormControlInput<string>()
  const checked = field.value === value

  return (
    <RadioButton
      {...field}
      value={value}
      data-testid={testId}
      checked={checked}
    >
      {children}
    </RadioButton>
  )
}
