import {
  RadioGroup,
  TRadioGroupHTMLProps,
} from '@mindfulchefuk/design-system/Forms/RadioButton/RadioGroup'
import React, { ReactElement } from 'react'
import { useFormControlInput } from '@mindfulchefuk/forms/hooks/useFormControlInput'

export const FormRadioGroup = (
  htmlProps: TRadioGroupHTMLProps
): ReactElement => {
  const inputProps = useFormControlInput<string | number | readonly string[]>()

  return <RadioGroup {...inputProps} {...htmlProps} />
}
